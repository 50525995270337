import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5f9549843c491168f35db1f0f93a39f6/e5bc7/build-habts-that-stick.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABRYrE0iag/wD/xAAYEAEAAwEAAAAAAAAAAAAAAAACAAEDBP/aAAgBAQABBQIc7UvkqI0bGiM10Wtz/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/AZqP/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8Bqv/EABwQAAEDBQAAAAAAAAAAAAAAABEAARASMUFRgf/aAAgBAQAGPwI4V36hUYBDaj//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhUTFBof/aAAgBAQABPyEUWPUSYJhzRTRONbNMuKj0iBip/9oADAMBAAIAAwAAABDEP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxA0X//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/EAR//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFxUfD/2gAIAQEAAT8Qr5atunJQOTZ+7AraUrJcPMNCwYQ2bfViNBU//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "habits",
            "title": "habits",
            "src": "/static/5f9549843c491168f35db1f0f93a39f6/4b190/build-habts-that-stick.jpg",
            "srcSet": ["/static/5f9549843c491168f35db1f0f93a39f6/e07e9/build-habts-that-stick.jpg 200w", "/static/5f9549843c491168f35db1f0f93a39f6/066f9/build-habts-that-stick.jpg 400w", "/static/5f9549843c491168f35db1f0f93a39f6/4b190/build-habts-that-stick.jpg 800w", "/static/5f9549843c491168f35db1f0f93a39f6/e5166/build-habts-that-stick.jpg 1200w", "/static/5f9549843c491168f35db1f0f93a39f6/b17f8/build-habts-that-stick.jpg 1600w", "/static/5f9549843c491168f35db1f0f93a39f6/e5bc7/build-habts-that-stick.jpg 5184w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`What is a habit?`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A habit is a routine or behavior that is performed regularly and, in many cases, automatically.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You don't have to make a conscious effort to perform a habit. It is just what you do. it is done in "AutoPilot mode".`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Here is a simple flowchart that demonstrates the principles of Habit Formation`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b07684989578b18c4ad54102997923d9/fa60d/habits.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "44.99999999999999%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABV0lEQVQoz2WS23KDMAxE+f8vzOQGIQQSLNuS6ft2pZROp3nY8Q0fr1Z0ZoaQGiQJRDJUDCmlUOVcpXFUrOuKLOVnbej7geoxDENouk/oAtbel7Qq1JTwhv2hrX2hma/3vYbNNu5tuN/vuN1ueD6fWJaFZgRdI6wF0FALgVphBM+PR1wYx5HOE7TUuPhXXkE8sG2h1ho6re6QJUwKebHkLDCpGFjK8XQKBw4sq8R8f8THeZ5pQH+rcXXLWDFPGdI/mEsKh60WlFKQc47RuJfTGjk5zMFvjXHuzn6BtRhKpt3ih2vk2CoBzGNvjHItbMj12gfIwd4Mn38A3xkSqAshKwHCLBOmaYqcZoZdGIOkJy6XS8jBPnrJf2EB3DvXbEWtmdJw5B+7HmxOJlCrxNq7uZ/5b/SR4Q40Y1nyLtHb7w4OhwNObMzr9eJDCefzGcfjMUrec/xf8jdquqKQxTD8/gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "habit flowchart",
                "title": "habit flowchart",
                "src": "/static/b07684989578b18c4ad54102997923d9/5a190/habits.png",
                "srcSet": ["/static/b07684989578b18c4ad54102997923d9/772e8/habits.png 200w", "/static/b07684989578b18c4ad54102997923d9/e17e5/habits.png 400w", "/static/b07684989578b18c4ad54102997923d9/5a190/habits.png 800w", "/static/b07684989578b18c4ad54102997923d9/c1b63/habits.png 1200w", "/static/b07684989578b18c4ad54102997923d9/29007/habits.png 1600w", "/static/b07684989578b18c4ad54102997923d9/fa60d/habits.png 1792w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p parentName="li">{`Please read through this`}</p>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Steps to develop a reading habit`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Take a book that you love to read. Don't take something that you have been wanting to read but found too boring or too hard. You may even try out Harry Potter.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Then fix a time when you will be reading. Reading at that specific time is the most important task here.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`After deciding the time and the book, decide the place where you will be reading.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Then set two alarms, the first one should be 15 minutes before your reading time and the second one should be at your reading time. The first alarm signals you to stop doing whatever you are doing and helps you to wrap up.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`After that, sign up for any habit tracker. I would recommend either 'Habitica' or 'Loop Habit Tracker'.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Then choose a minimum reading limit. I would recommend you to start with a single page and even reduce it if you want. The commitment of more than 1 page is a bit too much for a minimum amount`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`In your habit tracker, add the habit as "Read at `}{`[PLACE]`}{` at `}{`[TIME]`}{` for a minimum of `}{`[YOUR MINIMUM LIMIT]`}{`"`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Then, you have to make a streak of at least 18 days. Not missing once and doing it on time.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Over time you can increase the minimum limit. If you happen to miss once, that doesn't mean that you have lost the battle. Not down the reason for your failure then immediately move on. Although doing it at the same and at that same place is important they are not as important as the act of reading itself. If the time is up or the place isn't available, please do read.`}</li>
    </ul>
    <p>{`This checklist can be easily moulded for any habit that you want.`}</p>
    <hr></hr>
    <p>{`If you would like to learn more about Habit Formation, you should check out `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299/ref=sr_1_2?dchild=1&keywords=atomic&qid=1590737523&sr=8-2"
      }}>{`Atomic Habits`}</a>{` by James Clear.`}</p>
    <p>{`If you face any issues in forming habits or think that I missed something, feel free to comment and inform me. I will make sure to help you out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      